import { Button, Stack, Typography } from '@mui/material';
import type { EventControl } from 'core';
import type { FC } from 'react';
import { ControlCardList } from '../../../../components/event-control/control-card-list.js';
import { EditControlCardView } from '../../../../components/event-control/edit-control-card-view.js';
import type { EventState } from '../../store/event-state.js';

export interface Props extends EventState {
  controls: EventControl[];
  newControl?: EventControl;
  onAddControl: () => void;
  onNewControlCancel: () => void;
  onNewControlDone: (control: EventControl) => void;
}

export const EventControlsStepView: FC<Props> = ({
  controls,
  newControl,
  onAddControl,
  onNewControlCancel,
  onNewControlDone,
  details,
  eventControls,
  overlayImage,
}) => (
  <Stack spacing={3}>
    <Typography variant="h4">Event Controls</Typography>
    {controls.length ? (
      <ControlCardList
        controls={controls}
        editControl={eventControls.editing}
        eventType={details.all.type ?? 'long'}
        onChangeControl={(_, updated) => eventControls.saveEdit(updated)}
        onRemoveControl={(control) => eventControls.remove(control._id)}
        overlayImage={overlayImage.overlayImage}
        onStartEdit={eventControls.edit}
      />
    ) : (
      <Typography variant="body2" color="text.secondary" fontStyle="italic">
        Define all the controls that will be used in the courses of this event.
      </Typography>
    )}
    {newControl ? (
      <EditControlCardView
        control={newControl}
        eventType={details.all.type ?? 'long'}
        onCancel={onNewControlCancel}
        onDone={onNewControlDone}
      />
    ) : (
      <Button variant="outlined" onClick={onAddControl}>
        Add Control
      </Button>
    )}
  </Stack>
);
