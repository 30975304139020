import { XMLParser } from 'fast-xml-parser';
import type { EventInfo } from '../types.js';
import { IofV203 } from './iof-v203.js';
import { IofV3 } from './iof-v3.js';

const parser = new XMLParser({
  attributeNamePrefix: '',
  ignoreAttributes: false,
  // isArray: (_tagName, _jPath, _isLeafNode, isAttribute: boolean) => !isAttribute,
  trimValues: true,
});

export const processFile = async (file: File): Promise<EventInfo> => {
  console.log('onFile', file);

  // parse XML
  const content = parser.parse(await file.text()) as unknown;
  console.log(content);

  // detect content type
  if (IofV3.isIofV3(content)) {
    console.log('We can work with this');
    return IofV3.extractEventInfo(content);
  }
  if (IofV203.isIofV203(content)) {
    console.log('We can work with this');
    return IofV203.extractEventInfo(content);
  }

  return Promise.reject(
    new Error(
      'Unable to process this file. Currently only IOF XML v2.0.3 (preferred) and v3.0 is supported',
    ),
  );
};
