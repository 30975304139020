import { centroid, helpers } from '@turf/turf';
import type { Control, CourseControl } from 'core';
import { fromTurfPoint } from 'core';
import { ulid } from 'ulid';
import { asArray } from '../../../utils/array.js';
import type { EventInfo, NewCourse } from '../types.js';
import type { CourseVariation, IofV203Xml } from './iof-v203.types.js';
import { getSingleChild } from './utils.js';

export namespace IofV203 {
  export const isIofV203 = (content: unknown): content is IofV203Xml => {
    const courseData = getSingleChild(content, 'CourseData');
    if (!courseData) return false;

    const iofVersion = getSingleChild(courseData, 'IOFVersion');
    if (!iofVersion) return false;

    const version = getSingleChild(iofVersion, 'version');
    return version === '2.0.3';
  };

  export const extractEventInfo = ({
    CourseData: courseData,
  }: IofV203Xml): EventInfo => {
    const hasPosition = asArray(courseData.StartPoint).some(
      (pt) => !!pt.ControlPosition,
    );

    if (!hasPosition) {
      throw new Error('File has not been georeferenced.');
    }

    const controls = {
      ...asArray(courseData.StartPoint).reduce<Record<string, Control>>(
        (acc, pt) => ({
          ...acc,
          [pt.StartPointCode]: {
            lat: Number(pt.ControlPosition.y),
            long: Number(pt.ControlPosition.x),
            type: 'start',
          } satisfies Control,
        }),
        {},
      ),
      ...asArray(courseData.Control).reduce<Record<string, Control>>(
        (acc, pt) => ({
          ...acc,
          [String(pt.ControlCode)]: {
            lat: Number(pt.ControlPosition.y),
            long: Number(pt.ControlPosition.x),
          },
        }),
        {},
      ),
      ...asArray(courseData.FinishPoint).reduce<Record<string, Control>>(
        (acc, pt) => ({
          ...acc,
          [pt.FinishPointCode]: {
            lat: Number(pt.ControlPosition.y),
            long: Number(pt.ControlPosition.x),
            radius: 35,
            type: 'finish',
          } satisfies Control,
        }),
        {},
      ),
    };

    const center = centroid(
      helpers.points(
        asArray(courseData.FinishPoint).map((finish) => [
          finish.ControlPosition.x,
          finish.ControlPosition.y,
        ]),
      ),
    );

    const courses = asArray(courseData.Course).flatMap<NewCourse>((c) =>
      asArray(c.CourseVariation).map<NewCourse>((v) => ({
        _id: ulid(),
        title: Array.isArray(c.CourseVariation)
          ? `${c.CourseName} ${v.CourseVariationId}`
          : `${c.CourseName}`,
        controls: getCourseControls(v),
        defaultControlRadius: null,
        tags: [],
        labels: {},
      })),
    );
    return {
      controls,
      courses,
      location: fromTurfPoint(center.geometry),
    };
  };
}

const getCourseControls = (v: CourseVariation): CourseControl[] | undefined => {
  const controls: CourseControl[] = [];
  if (v.StartPointCode) {
    controls.push({ _id: String(v.StartPointCode) });
  }
  controls.push(
    ...asArray(v.CourseControl).map<CourseControl>((cc) => ({
      _id: String(cc.ControlCode),
    })),
  );
  if (v.FinishPointCode) {
    controls.push({ _id: String(v.FinishPointCode) });
  }
  return controls.length ? controls : undefined;
};
