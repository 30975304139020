import type { Course, Event } from 'core';
import { fromEventControls } from 'core';
import type { EventState } from './store/event-state.js';
import type { NewCourse } from './types.js';

interface ViewProps {
  onFinishClick: () => Promise<void>;
  state: EventState;
}

interface Props {
  handleCreateEvent: (event: Event.CreateWithCourses) => Promise<void>;
  state: EventState;
}

const stripId = (value: NewCourse): Course.CreateWithoutEventId => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { _id, ...rest } = value;
  return rest;
};

export const withViewState =
  <P extends Props>(Component: React.ComponentType<ViewProps>): React.FC<P> =>
  ({ handleCreateEvent, state }) => {
    const onFinishClick = (): Promise<void> => {
      const event = {
        ...state.details.getDetails(),
        controls: fromEventControls(state.eventControls.all),
        courses: state.courses.all.map(stripId) ?? [
          { title: 'Course', controls: [], tags: ['default'], labels: {} },
        ],
        overlayImage: state.overlayImage.tempFile ?? null,
      } as Event.CreateWithCourses;

      console.log('onFinishClick', event);
      return handleCreateEvent(event);
    };

    return <Component onFinishClick={onFinishClick} state={state} />;
  };
