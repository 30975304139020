import {
  Alert,
  Box,
  Card,
  CardContent,
  Collapse,
  Grid,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import type { ResolvedControl } from 'core';
import type { FC } from 'react';
import { FileDrop } from '../../../../components/file-drop/view.js';
import { ControlsLayer } from '../../../../components/map/layers/course.js';
import { MapStyles } from '../../../../components/map/map-styles.js';
import ControlMap from '../../control-map.js';
import { ProjectionPicker } from '../../projection-picker.js';
import type { EventInfo } from '../../types.js';

interface Props {
  eventInfo: EventInfo | undefined;
  onChangeProjection: (projection: string) => void;
  onIofFile: (file: File) => Promise<void>;
  processedEventInfo: EventInfo | undefined;
  resolvedControls: ResolvedControl[] | undefined;
}

export const EventFileUploadStepView: FC<Props> = ({
  eventInfo,
  onIofFile,
  onChangeProjection,
  resolvedControls,
}) => (
  <Box width="100%">
    <Card>
      <CardContent>
        <Collapse in={!eventInfo} sx={{ width: '100%' }}>
          <Stack spacing={3}>
            <Typography variant="h4">Upload Event File</Typography>
            <Typography variant="body1">
              To get started, you can import an event file. This can be exported
              from OCAD, Purple Pen or OOM.
            </Typography>
            <Typography variant="body1">
              Importing the event file will fill in the event location and
              create all of the courses and controls.
            </Typography>
            <FileDrop
              accept={{
                'text/xml': ['.xml'],
              }}
              helperText="Drag 'n' drop XML file here, or click to select"
              helperTextDragOver="Drop here"
              helperTextReject="Must be XML file"
              onFile={onIofFile}
              size="large"
              sx={{ height: '6rem' }}
            />
            <Alert severity="info">
              Currently only IOF XML v2.0.3 (preferred) and v3.0 files are
              supported. If you have other versions or file types that you wish
              to have supported, please email examples to{' '}
              <Link href="mailto:dev@otrax.app">dev@otrax.app</Link>.
            </Alert>
          </Stack>
        </Collapse>
        <Collapse in={!!eventInfo}>
          <Grid container spacing={3}>
            {!eventInfo?.projection ? (
              <>
                <Grid item xs={12}>
                  <Typography variant="body1">
                    Select the projection used in the file provided.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <ProjectionPicker
                    onChange={(_, value) =>
                      value && onChangeProjection(value[1])
                    }
                  />
                </Grid>
              </>
            ) : null}
            <Grid item xs={12}>
              <Collapse in={!resolvedControls?.length}>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  fontStyle="italic"
                >
                  Select projection to display control locations.
                </Typography>
              </Collapse>
              <Collapse in={!!resolvedControls?.length}>
                <ControlMap
                  height="100%"
                  minHeight={320}
                  maxHeight={480}
                  controls={resolvedControls}
                  mapStyle={MapStyles.SATELLITE}
                >
                  {resolvedControls ? (
                    <ControlsLayer
                      id="controls"
                      eventType={null}
                      controls={resolvedControls}
                    />
                  ) : undefined}
                </ControlMap>
              </Collapse>
            </Grid>
          </Grid>
        </Collapse>
      </CardContent>
    </Card>
  </Box>
);
