import { Container } from '@mui/material';
import { PageTitle } from '../../components/page-title/page-title.js';
import { PageCard } from '../../components/step-page/page-card.js';
import { CarouselStepper } from './collapse-stepper.js';
import { EventControlsStep } from './steps/event-controls/controller.js';
import { CoursesStep } from './steps/event-courses/controller.js';
import { EventDetailsStep } from './steps/event-details/controller.js';
import { EventFileUploadStep } from './steps/event-file-upload/controller.js';
import { EventMapOverlayStep } from './steps/event-map-overlay/controller.js';
import { withViewState } from './view-state.js';

export const CreateEventView = withViewState(({ onFinishClick, state }) => (
  <PageTitle title="New Event">
    <Container maxWidth="md" sx={{ my: 3 }}>
      <CarouselStepper
        onFinishClick={onFinishClick}
        steps={[
          {
            label: 'Event File',
            canSkip: !state.details.eventFileUploaded,
            content: <EventFileUploadStep state={state} />,
            isValid: state.details.eventFileUploaded,
          },
          {
            label: 'Event Details',
            content: (
              <PageCard>
                <EventDetailsStep state={state} />
              </PageCard>
            ),
            isValid: state.details.valid,
          },
          {
            label: 'Map Overlay',
            canSkip: !state.overlayImage.overlayImage,
            content: (
              <PageCard>
                <EventMapOverlayStep state={state} />
              </PageCard>
            ),
            isValid: !!state.overlayImage.overlayImage,
          },
          {
            label: 'Controls',
            canSkip: !Object.keys(state.eventControls.all).length,
            content: <EventControlsStep state={state} />,
            isValid:
              Object.keys(state.eventControls.all).length > 0 &&
              !state.eventControls.adding &&
              !state.eventControls.editing,
          },
          {
            label: 'Courses',
            content: <CoursesStep state={state} />,
            isValid: !state.courses.editing,
          },
        ]}
      />
    </Container>
  </PageTitle>
));
