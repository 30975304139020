const isRecord = (value: unknown): value is Record<string, unknown> =>
  value !== undefined &&
  value !== null &&
  typeof value === 'object' &&
  !Array.isArray(value);

export const getSingleChild = (
  parent: unknown,
  key: string,
): unknown | undefined => {
  if (!isRecord(parent)) return undefined;
  if (!(key in parent)) return undefined;

  const child = parent[key];
  if (!Array.isArray(child)) return child;
  if (child.length !== 1) return undefined;
  return child[0];
};

export const getArrayChild = (
  parent: unknown,
  key: string,
): unknown | undefined => {
  if (!isRecord(parent)) return undefined;
  if (!(key in parent)) return undefined;

  const child = parent[key];
  if (!Array.isArray(child)) return [child];
  return child;
};
