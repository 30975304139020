import { LocationOff as NoLocationIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  List,
  ListItem,
  ListItemButton,
  Stack,
  Typography,
} from '@mui/material';
import { Container, Draggable } from '@smooth-dnd/react';
import { controlHasLatLong, isRogaine } from 'core';
import { FormikTextField } from '../../../../../components/formik-fields/text-field.js';
import { ReactComponent as StartIcon } from '../../../../../components/map/markers/isom-701.svg';
import { ReactComponent as ControlIcon } from '../../../../../components/map/markers/isom-703.svg';
import { ReactComponent as FinishIcon } from '../../../../../components/map/markers/isom-706.svg';
import { AddControl } from './add-control.js';
import { withViewState } from './view-state.js';

export const EditCourse = withViewState(
  ({
    eventType,
    formik,
    onAddControl,
    onCancelClick,
    onDoneClick,
    onDrop,
    resolvedControls,
  }) => {
    return (
      <Stack spacing={3}>
        <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
          <LoadingButton
            color="primary"
            loading={formik.isSubmitting}
            onClick={onCancelClick}
            variant="outlined"
          >
            Cancel
          </LoadingButton>
          <LoadingButton
            color="primary"
            loading={formik.isSubmitting}
            onClick={onDoneClick}
            variant="contained"
          >
            Done
          </LoadingButton>
        </Stack>
        <FormikTextField id="title" formik={formik} label="Title" fullWidth />
        {isRogaine(eventType) ? (
          <Alert severity="info">
            Rogaine course will include all controls.
          </Alert>
        ) : (
          <>
            <Stack>
              <Typography>Controls</Typography>
              <Typography
                color="text.secondary"
                fontStyle="italic"
                fontSize="0.8em"
              >
                (drag to re-order)
              </Typography>
              <List
                sx={{
                  backgroundColor: (theme) => theme.palette.background.paper,
                  borderWidth: 1,
                  borderStyle: 'solid',
                  borderColor: (theme) => theme.palette.divider,
                  borderRadius: (theme) =>
                    `${theme.shape.borderRadius.toString()}px`,
                  py: 0,
                }}
              >
                {resolvedControls.start ? (
                  <ListItem sx={{ justifyContent: 'space-between' }}>
                    <Stack direction="row" spacing={2}>
                      <StartIcon height={24} width={24} />
                      <Typography>
                        Start ({resolvedControls.start._id})
                      </Typography>
                    </Stack>
                    {!controlHasLatLong(resolvedControls.start) ? (
                      <NoLocationIcon color="error" />
                    ) : null}
                  </ListItem>
                ) : null}
                <Container onDrop={onDrop} removeOnDropOut={true}>
                  {resolvedControls.controls.map((control) => {
                    return (
                      <Draggable key={control._id}>
                        <ListItemButton
                          sx={{
                            cursor: 'ns-resize',
                            justifyContent: 'space-between',
                            overflow: 'auto',
                          }}
                        >
                          <Stack direction="row" spacing={2}>
                            {control.type === 'start' ? (
                              <>
                                <StartIcon height={24} width={24} />
                                <Typography>Start ({control._id})</Typography>
                              </>
                            ) : control.type === 'finish' ? (
                              <>
                                <FinishIcon height={24} width={24} />
                                <Typography>Finish ({control._id})</Typography>
                              </>
                            ) : (
                              <>
                                <ControlIcon height={24} width={24} />
                                <Typography>{control._id}</Typography>
                              </>
                            )}
                          </Stack>
                          {!controlHasLatLong(control) ? (
                            <NoLocationIcon color="error" />
                          ) : null}
                        </ListItemButton>
                      </Draggable>
                    );
                  })}
                </Container>
                {resolvedControls.finish ? (
                  <ListItem sx={{ justifyContent: 'space-between' }}>
                    <Stack direction="row" spacing={2}>
                      <FinishIcon height={24} width={24} />
                      <Typography>
                        Finish ({resolvedControls.finish._id})
                      </Typography>
                    </Stack>
                    {!controlHasLatLong(resolvedControls.finish) ? (
                      <NoLocationIcon color="error" />
                    ) : null}
                  </ListItem>
                ) : null}
              </List>
            </Stack>
            <AddControl onAddControl={onAddControl} />
          </>
        )}
        <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
          <LoadingButton
            color="primary"
            loading={formik.isSubmitting}
            onClick={onCancelClick}
            variant="outlined"
          >
            Cancel
          </LoadingButton>
          <LoadingButton
            color="primary"
            loading={formik.isSubmitting}
            onClick={onDoneClick}
            variant="contained"
          >
            Done
          </LoadingButton>
        </Stack>
      </Stack>
    );
  },
);
