import type { ButtonProps } from '@mui/material';
import { Button, CircularProgress } from '@mui/material';
import type { FC } from 'react';
import { useState } from 'react';

interface Props extends ButtonProps {
  loadingCaption?: string;
  task: () => Promise<unknown>;
}

interface ViewProps extends Props {
  isLoading: boolean;
}

export const LoadingButtonView: FC<ViewProps> = (props) => {
  const {
    children,
    disabled,
    isLoading,
    loadingCaption,
    sx,
    task,
    variant,
    ...rest
  } = props;

  return (
    <Button
      {...rest}
      disabled={isLoading || disabled}
      onClick={!isLoading ? () => task() : undefined}
      sx={{ display: 'flex', alignItems: 'center', ...sx }}
      variant={variant ?? 'outlined'}
    >
      {isLoading ? (
        <>
          <CircularProgress
            color="inherit"
            role="status"
            size="0.8rem"
            sx={{ marginRight: '0.5rem' }}
          />
          <span>{loadingCaption}</span>
        </>
      ) : (
        children
      )}
    </Button>
  );
};

export const LoadingButton: FC<Props> = (props) => {
  const { children, task, ...rest } = props;
  const [isLoading, setLoading] = useState(false);

  const wrappedTask = (): Promise<unknown> => {
    setLoading(true);
    return task()
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  return (
    <LoadingButtonView {...rest} isLoading={isLoading} task={wrappedTask}>
      {children}
    </LoadingButtonView>
  );
};
