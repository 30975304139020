import { useMediaQuery, type Theme } from '@mui/material';
import { useState } from 'react';

export interface WithTrack {
  activeStep: number;
  narrowScreen: boolean;
  nextStep: () => void;
  previousStep: () => void;
  resetStepper: () => void;
  setActiveStep: (step: number) => void;
  skippedSteps: number[];
  skipStep: () => void;
}

export const withStepper =
  <P extends object>(
    Component: React.ComponentType<P & WithTrack>,
  ): React.FC<P> =>
  (props) => {
    const [activeStep, setActiveStep] = useState(0);
    const [skippedSteps, setSkippedSteps] = useState<number[]>([]);
    const narrowScreen = useMediaQuery((theme: Theme) =>
      theme.breakpoints.down('sm'),
    );

    const setStep = (step: number): void => {
      setActiveStep(step);
      if (skippedSteps.includes(step)) {
        setSkippedSteps(skippedSteps.filter((s) => s !== step));
      }
      window.scrollTo({
        top: 0,
        left: undefined,
        behavior: 'smooth',
      });
    };

    const nextStep = (): void => setStep(activeStep + 1);
    const previousStep = (): void => setStep(activeStep - 1);
    const resetStepper = (): void => {
      setSkippedSteps([]);
      setActiveStep(0);
    };
    const skipStep = (): void => {
      setSkippedSteps([...skippedSteps, activeStep]);
      setStep(activeStep + 1);
    };

    return (
      <Component
        {...props}
        activeStep={activeStep}
        narrowScreen={narrowScreen}
        nextStep={nextStep}
        previousStep={previousStep}
        resetStepper={resetStepper}
        setActiveStep={setStep}
        skippedSteps={skippedSteps}
        skipStep={skipStep}
      />
    );
  };
