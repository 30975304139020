import { memo } from 'react';
import type { EventState } from '../../store/event-state.js';
import { EventCoursesView } from './view.js';

interface Props {
  state: EventState;
}

export const CoursesStep = memo<Props>(({ state }) => {
  return EventCoursesView({ ...state });
});
