import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  MoreVert as MoreIcon,
} from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Collapse,
  Grid,
  IconButton,
  List,
  ListItemButton,
  Stack,
  Typography,
} from '@mui/material';
import { fromEventControls, isRogaine, resolveControls } from 'core';
import { useMemo } from 'react';
import { CourseLayer } from '../../../../components/map/layers/course.js';
import { OverlayImageLayer } from '../../../../components/map/layers/overlay-image.js';
import { MapStyles } from '../../../../components/map/map-styles.js';
import { MenuController } from '../../../../components/menu-controller/menu-controller.js';
import { sortByCourseTitle } from '../../../../utils/course-sort.js';
import ControlMap from '../../control-map.js';
import { EditCourse } from './edit/view.js';
import { withViewState } from './view-state.js';

export const EventCoursesView = withViewState(
  ({
    controls,
    courses,
    details,
    overlayImage,
    selectedCourseId,
    setSelectedCourse,
  }) => {
    const allCourses = [...courses.all].sort(sortByCourseTitle);
    const mapCourse = useMemo(
      () =>
        courses.editing ??
        allCourses.find((course) => course._id === selectedCourseId),
      [courses.editing, selectedCourseId, allCourses.find],
    );
    return (
      <Stack spacing={3} width="100%">
        {allCourses.length || courses.editing ? (
          <Card>
            <Grid container>
              <Grid
                item
                xs={12}
                md={4}
                sx={{
                  maxHeight: { xs: '50vh', md: '75vh' },
                  overflowX: 'auto',
                }}
              >
                <CardContent>
                  <Collapse in={!courses.editing}>
                    {!courses.editing ? (
                      <List sx={{ py: 0, width: '100%' }}>
                        {allCourses.map((course) => (
                          <ListItemButton
                            key={course._id}
                            onClick={() => setSelectedCourse(course)}
                            selected={course._id === selectedCourseId}
                            sx={{
                              borderWidth: 1,
                              borderStyle: 'solid',
                              borderColor: (theme) =>
                                course._id === selectedCourseId
                                  ? theme.palette.primary.main
                                  : theme.palette.divider,
                              borderRadius: (theme) =>
                                `${theme.shape.borderRadius.toString()}px`,
                              mb: 2,
                              overflow: 'auto',
                            }}
                          >
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              width="100%"
                            >
                              <Stack>
                                <Typography variant="body1">
                                  {course.title}
                                </Typography>
                                {course.controls &&
                                course.controls.length > 2 ? (
                                  <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    fontStyle="italic"
                                  >
                                    {course.controls.length - 2}{' '}
                                    {course.controls.length === 3
                                      ? 'control'
                                      : 'controls'}
                                  </Typography>
                                ) : null}
                              </Stack>
                            </Stack>
                            <Box>
                              <MenuController
                                button={(handleClick) => (
                                  <IconButton onClick={handleClick}>
                                    <MoreIcon />
                                  </IconButton>
                                )}
                                horizontalAlign="right"
                                items={[
                                  {
                                    key: 'edit',
                                    icon: <EditIcon fontSize="small" />,
                                    label: 'Edit',
                                    onClick: () => courses.edit(course),
                                  },
                                  {
                                    key: 'remove',
                                    confirm: true,
                                    icon: <DeleteIcon fontSize="small" />,
                                    label: 'Remove',
                                    onClick: () => courses.remove(course),
                                  },
                                ]}
                              />
                            </Box>
                          </ListItemButton>
                        ))}
                        <Button
                          onClick={courses.add}
                          variant="outlined"
                          fullWidth
                        >
                          Add Course
                        </Button>
                      </List>
                    ) : null}
                  </Collapse>
                  <Collapse in={!!courses.editing}>
                    {courses.editing ? (
                      <EditCourse
                        course={courses.editing}
                        eventControls={controls.all}
                        eventType={details.all.type ?? null}
                        onCancelClick={courses.cancelEdit}
                        onDoneClick={courses.saveEdit}
                        onUpdate={courses.updateEdit}
                      />
                    ) : null}
                  </Collapse>
                </CardContent>
              </Grid>
              <Grid
                item
                xs={12}
                md={8}
                sx={{ maxHeight: '75vh', minHeight: '50vh' }}
              >
                {mapCourse ? (
                  mapCourse.controls?.length || isRogaine(details.all.type) ? (
                    <ControlMap
                      height="100%"
                      controls={resolveControls(
                        mapCourse.controls,
                        fromEventControls(controls.all),
                        details.all.type,
                      )}
                      mapStyle={MapStyles.SATELLITE}
                    >
                      {overlayImage.overlayImage ? (
                        <OverlayImageLayer
                          id="selected-course"
                          corners={overlayImage.overlayImage?.corners}
                          imageUrl={overlayImage.overlayImage?.url}
                          interpolateOpacity={true}
                        />
                      ) : null}
                      <CourseLayer
                        course={{ ...mapCourse }}
                        event={{
                          controls: fromEventControls(controls.all),
                          type: details.all.type,
                        }}
                      />
                    </ControlMap>
                  ) : (
                    <CardContent>
                      <Alert severity="info">
                        Add some controls to display the map.
                      </Alert>
                    </CardContent>
                  )
                ) : null}
              </Grid>
            </Grid>
          </Card>
        ) : (
          <Card>
            <CardContent>
              <Stack spacing={3}>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  fontStyle="italic"
                >
                  There are no courses defined for this event. Please add some.
                </Typography>

                <Button onClick={courses.add} variant="outlined" fullWidth>
                  Add Course
                </Button>
              </Stack>
            </CardContent>
          </Card>
        )}
      </Stack>
    );
  },
);
