import type { ComponentType } from 'react';
import { useState } from 'react';
import { sortByCourseTitle } from '../../../../utils/course-sort.js';
import type {
  CoursesActions,
  CoursesState,
} from '../../store/courses-state.js';
import type { EventControlsState } from '../../store/event-controls-state.js';
import type { EventDetailsState } from '../../store/event-details-state.js';
import type { EventState } from '../../store/event-state.js';
import type { OverlayImageState } from '../../store/overlay-image-state.js';
import type { NewCourse } from '../../types.js';

interface Props extends EventState {}

interface ViewProps {
  selectedCourseId: string | undefined;
  setSelectedCourse: (course: NewCourse | undefined) => void;
  controls: EventControlsState;
  courses: CoursesState & CoursesActions;
  details: EventDetailsState;
  overlayImage: OverlayImageState;
}

export const withViewState =
  <P extends Props>(Component: ComponentType<ViewProps>): React.FC<P> =>
  ({ eventControls: controls, courses, details, overlayImage }) => {
    const [selectedCourseId, setSelectedCourseId] = useState<
      string | undefined
    >(
      courses.all.length
        ? [...courses.all].sort(sortByCourseTitle)[0]._id
        : undefined,
    );

    return (
      <Component
        selectedCourseId={selectedCourseId}
        setSelectedCourse={(course) => {
          setSelectedCourseId(course?._id);
        }}
        controls={controls}
        courses={courses}
        details={details}
        overlayImage={overlayImage}
      />
    );
  };
