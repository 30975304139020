import type { EventControl } from 'core';
import { sortEventControl } from 'core';
import type { FC } from 'react';
import { memo, useMemo } from 'react';
import type { EventState } from '../../store/event-state.js';
import { NoLocation } from './no-location.js';
import { EventControlsStepView } from './view.js';

interface Props {
  state: EventState;
}

export const EventControlsStep: FC<Props> = memo(({ state }) => {
  const controlArray = useMemo(
    () =>
      Object.entries(state.eventControls.all ?? {})
        .map(([_id, control]) => ({ ...control, _id }))
        .sort(sortEventControl),
    [state.eventControls.all],
  );

  const location = state.details.all.location;
  if (!location) return NoLocation({});

  const onAddControl = (): void =>
    state.eventControls.add('', location, 'control');

  const onNewControlCancel = (): void => state.eventControls.cancelEdit();

  const onNewControlDone = (control: EventControl): void => {
    state.eventControls.saveEdit(control);
  };

  return EventControlsStepView({
    controls: controlArray,
    newControl: state.eventControls.adding,
    onAddControl,
    onNewControlCancel,
    onNewControlDone,
    ...state,
  });
});
