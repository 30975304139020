import { centroid, helpers } from '@turf/turf';
import type { Control, CourseControl } from 'core';
import { fromTurfPoint } from 'core';
import { ulid } from 'ulid';
import { asArray } from '../../../utils/array.js';
import type { EventInfo, NewCourse } from '../types.js';
import type { IofV3Xml } from './iof-v3.types.js';
import { projections } from './projections.js';
import { getSingleChild } from './utils.js';

export namespace IofV3 {
  export const isIofV3 = (content: unknown): content is IofV3Xml => {
    const courseData = getSingleChild(content, 'CourseData');
    if (!courseData) return false;

    const iofVersion = getSingleChild(courseData, 'iofVersion');
    return iofVersion === '3.0';
  };

  export const extractEventInfo = ({
    CourseData: courseData,
  }: IofV3Xml): EventInfo => {
    const controls = {
      ...asArray(courseData.RaceCourseData.Control).reduce<
        Record<string, Control>
      >(
        (acc, pt) => ({
          ...acc,
          [String(pt.Id)]: {
            lat: Number(pt.Position.lat),
            long: Number(pt.Position.lng),
          },
        }),
        {},
      ),
    };

    const starts = asArray(courseData.RaceCourseData.Course).flatMap((c) =>
      asArray(c.CourseControl).filter((cc) => cc.type === 'Start'),
    );
    starts.forEach(({ Control }) => {
      const match = controls[String(Control)];
      if (match) match.type = 'start';
    });
    const finishes = asArray(courseData.RaceCourseData.Course).flatMap((c) =>
      asArray(c.CourseControl).filter((cc) => cc.type === 'Finish'),
    );
    finishes.forEach(({ Control }) => {
      const match = controls[String(Control)];
      if (match) match.type = 'finish';
    });

    const center = centroid(
      helpers.points(
        finishes.flatMap((finish) => {
          const control = controls[finish.Control];
          if (!control) return [];
          return [[control.long, control.lat]];
        }),
      ),
    );

    const courses = asArray(
      courseData.RaceCourseData.Course,
    ).flatMap<NewCourse>((c) => ({
      _id: ulid(),
      title: `${c.Name}`,
      controls: [
        ...asArray(c.CourseControl)
          .map<CourseControl>((cc) => ({
            _id: String(cc.Control),
            type:
              cc.type === 'Start'
                ? 'start'
                : cc.type === 'Finish'
                  ? 'finish'
                  : undefined,
          }))
          .filter((control, index) => control.type !== 'start' || index === 0),
      ],
      defaultControlRadius: null,
      tags: [],
      labels: {},
    }));
    return {
      controls,
      courses,
      location: fromTurfPoint(center.geometry),
      projection: projections['EPSG:4326'],
      title: courseData.Event.Name,
    };
  };
}
