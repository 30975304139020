import { bbox, helpers } from '@turf/turf';
import type { ResolvedControl } from 'core';
import { bboxToBounds, isLatLong } from 'core';
import type { Property } from 'csstype';
import type { FC, PropsWithChildren } from 'react';
import { useEffect, useMemo } from 'react';
import { useMap } from 'react-map-gl';
import { MAP_PADDING, MapView } from '../../components/map/view.js';
import { withMap } from '../../hoc/with-map.js';
import { magneticDeclination } from '../../utils/geo.js';

interface Props {
  controls?: ResolvedControl[];
  height?:
    | Property.Height<string | number>
    | NonNullable<Property.Height<string | number> | undefined>[]
    | undefined;
  mapStyle: string;
  maxHeight?:
    | Property.Height<string | number>
    | NonNullable<Property.Height<string | number> | undefined>[]
    | undefined;
  maxWidth?:
    | Property.Width<string | number>
    | NonNullable<Property.Width<string | number> | undefined>[]
    | undefined;
  minHeight?:
    | Property.Height<string | number>
    | NonNullable<Property.Height<string | number> | undefined>[]
    | undefined;
  minWidth?:
    | Property.Width<string | number>
    | NonNullable<Property.Width<string | number> | undefined>[]
    | undefined;
  width?:
    | Property.Width<string | number>
    | NonNullable<Property.Width<string | number> | undefined>[]
    | undefined;
}

const controller =
  (View: typeof MapView): FC<PropsWithChildren<Props>> =>
  (props) => {
    const { default: map } = useMap();

    useEffect(() => {
      if (!map) return;

      const resize = (): void => {
        map.resize();
      };

      map.on('idle', resize);

      return () => {
        map.off('idle', resize);
      };
    }, [map]);

    const bounds = useMemo(() => {
      if (
        !props.controls ||
        !props.controls.filter((control) => control.lat && control.long).length
      )
        return;

      const box = bbox(
        helpers.points(
          props.controls
            .map((control) => [control.long, control.lat])
            .filter(isLatLong),
        ),
      );
      return bboxToBounds(box);
    }, [props.controls]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
      if (!map || !bounds) return;

      map.fitBounds(bounds, {
        bearing: magneticDeclination(bounds[0], bounds[1]),
        padding: {
          left: MAP_PADDING,
          right: MAP_PADDING,
          top: MAP_PADDING,
          bottom: MAP_PADDING,
        },
      });
    }, [map, bounds]);

    return View({
      ...props,
      bearing: bounds ? magneticDeclination(bounds[0], bounds[1]) : 0,
      bounds,
    });
  };

export default withMap(controller(MapView));
