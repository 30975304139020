import {
  ChevronRight as NextIcon,
  ChevronLeft as PrevIcon,
  Save as SaveIcon,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Collapse,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import type { Event } from 'core';
import type { FC, ReactNode } from 'react';
import { LoadingButton } from '../../components/loading-button/loading-button.js';
import type { WithTrack } from './with-stepper.js';
import { withStepper } from './with-stepper.js';

export type FormData = Partial<Event.Create & { crs: string }>;

interface StepInfo {
  canSkip?: boolean;
  content: ReactNode;
  disabled?: boolean;
  isValid?: boolean;
  label: string;
  onNextClick?: () => void;
  onPreviousClick?: () => void;
  onSkipClick?: () => void;
}

interface Props {
  onFinishClick: () => Promise<void>;
  steps: StepInfo[];
}

export const CarouselStepper: FC<Props> = withStepper(
  ({
    activeStep,
    narrowScreen,
    nextStep,
    onFinishClick,
    previousStep,
    skippedSteps,
    skipStep,
    steps,
  }) => (
    <Stack spacing={3} width="100%">
      <Stepper activeStep={activeStep}>
        {steps
          .filter(({ disabled }) => !disabled)
          .map(({ canSkip, label }, index) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: {
              optional?: React.ReactNode;
            } = {};
            if (canSkip) {
              labelProps.optional = (
                <Typography variant="caption">Optional</Typography>
              );
            }
            if (skippedSteps.includes(index)) {
              stepProps.completed = false;
            }
            return (
              // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
              <Step key={index} {...stepProps}>
                <StepLabel
                  {...labelProps}
                  optional={
                    !narrowScreen || index === activeStep
                      ? labelProps.optional
                      : null
                  }
                >
                  {!narrowScreen || index === activeStep ? label : null}
                </StepLabel>
              </Step>
            );
          })}
      </Stepper>
      <Navigator
        activeStep={activeStep}
        nextStep={nextStep}
        onFinishClick={onFinishClick}
        previousStep={previousStep}
        skipStep={skipStep}
        steps={steps}
      />
      <Stack>
        {steps
          .filter(({ disabled }) => !disabled)
          .map((step, index) => (
            <Collapse
              in={activeStep === index}
              // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
              key={index}
              sx={{ width: '100%', py: 0, px: 1 }}
            >
              {activeStep === index ? step.content : null}
            </Collapse>
          ))}
      </Stack>
      <Navigator
        activeStep={activeStep}
        nextStep={nextStep}
        onFinishClick={onFinishClick}
        previousStep={previousStep}
        skipStep={skipStep}
        steps={steps}
      />
    </Stack>
  ),
);

const Navigator: FC<
  Pick<
    Props & WithTrack,
    | 'activeStep'
    | 'nextStep'
    | 'onFinishClick'
    | 'previousStep'
    | 'skipStep'
    | 'steps'
  >
> = ({
  activeStep,
  nextStep,
  onFinishClick,
  previousStep,
  skipStep,
  steps,
}) => (
  <>
    {activeStep < steps.length ? (
      <Box sx={{ display: 'flex', flexDirection: 'row', px: 1 }}>
        <Button
          startIcon={<PrevIcon />}
          color="inherit"
          variant="outlined"
          disabled={activeStep === 0}
          onClick={() => {
            steps[activeStep].onPreviousClick?.();
            previousStep();
          }}
          sx={{ mr: 1 }}
        >
          Back
        </Button>
        <Box sx={{ flex: '1 1 auto' }} />
        {steps[activeStep].canSkip && (
          <Button
            color="inherit"
            onClick={() => {
              steps[activeStep].onSkipClick?.();
              skipStep();
            }}
            sx={{ mr: 1 }}
          >
            Skip
          </Button>
        )}
        {activeStep === steps.length - 1 ? (
          <LoadingButton
            task={onFinishClick}
            loadingCaption="Saving..."
            disabled={!steps[activeStep].isValid}
            startIcon={<SaveIcon />}
            variant="contained"
          >
            Create Now
          </LoadingButton>
        ) : (
          <Button
            endIcon={<NextIcon />}
            onClick={() => {
              steps[activeStep].onNextClick?.();
              nextStep();
            }}
            disabled={!steps[activeStep].isValid}
            variant="contained"
          >
            Next
          </Button>
        )}
      </Box>
    ) : undefined}
  </>
);
