import type {
  Control,
  CourseControl,
  GeoJSONPoint,
  ResolvedControl,
} from 'core';
import { resolveLinearControls, toEventControls } from 'core';
import proj4 from 'proj4';
import type { FC } from 'react';
import { useEffect, useMemo, useState } from 'react';
import { processFile } from '../../file-processing/detector.js';
import { projections } from '../../file-processing/projections.js';
import type { EventState } from '../../store/event-state.js';
import type { EventInfo } from '../../types.js';
import { EventFileUploadStepView } from './view.js';

interface Props {
  state: EventState;
}

export const EventFileUploadStep: FC<Props> = ({ state }) => {
  const [eventInfo, setEventInfo] = useState<EventInfo>();

  const projection = state.details.projection;

  const processedEventInfo = useMemo<EventInfo | undefined>(() => {
    const proj = eventInfo?.projection ?? projection;
    if (!proj || !eventInfo) return undefined;

    const convertCourseControl = (control: CourseControl): CourseControl => {
      if (
        control.lat === null ||
        control.lat === undefined ||
        control.long === null ||
        control.long === undefined
      ) {
        return control;
      }
      const converted = proj4(proj, projections['EPSG:4326'], [
        control.long,
        control.lat,
      ]);
      return {
        ...control,
        lat: converted[1],
        long: converted[0],
      };
    };
    const convertPoint = (point: GeoJSONPoint): GeoJSONPoint => ({
      type: 'Point',
      coordinates: proj4(proj, projections['EPSG:4326'], point.coordinates),
    });
    const convertControls = (
      controls: Record<string, Control>,
    ): Record<string, Control> =>
      Object.keys(controls).reduce<Record<string, Control>>((acc, key) => {
        // TODO directly to EventControl
        const control = controls[key];
        const converted = proj4(proj, projections['EPSG:4326'], [
          control.long,
          control.lat,
        ]);
        return {
          ...acc,
          [key]: {
            ...control,
            lat: converted[1],
            long: converted[0],
          },
        };
      }, {});

    const details = {
      controls: convertControls(eventInfo.controls),
      courses: eventInfo.courses.map((course) => ({
        ...course,
        controls: course.controls
          ? course.controls.map(convertCourseControl)
          : [],
      })),
      location: convertPoint(eventInfo.location),
    };
    return details;
  }, [projection, eventInfo]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: avoid loops
  useEffect(() => {
    if (!processedEventInfo) return;

    state.details.eventFileDetails(
      processedEventInfo.title,
      processedEventInfo.location,
      processedEventInfo.projection,
    );
    state.eventControls.reset(toEventControls(processedEventInfo.controls));
    state.courses.reset(processedEventInfo.courses);
  }, [processedEventInfo]);

  const resolvedControls = useMemo(
    () =>
      processedEventInfo
        ? Object.values(
            resolveLinearControls(
              processedEventInfo.courses.flatMap(
                (course) => course.controls ?? [],
              ),
              processedEventInfo.controls,
            ).reduce<Record<string, ResolvedControl>>((acc, control) => {
              if (control._id in acc) return acc;
              return { ...acc, [control._id]: control };
            }, {}),
          )
        : undefined,
    [processedEventInfo],
  );

  const onIofFile = async (file: File): Promise<void> => {
    const info = await processFile(file);
    setEventInfo({ title: file.name, ...info });
  };

  return EventFileUploadStepView({
    eventInfo,
    onChangeProjection: (projection) => state.details.setProjection(projection),
    onIofFile,
    processedEventInfo,
    resolvedControls,
  });
};
