import { captureException } from '@sentry/react';
import axios from 'axios';
import type { Event } from 'core';
import { useSnackbar } from 'notistack';
import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import uri from 'uri-tag';
import { useEventState } from './store/event-state.js';
import { CreateEventView } from './view.js';

const Controller: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const state = useEventState();

  const handleCreateEvent = async (
    event: Event.CreateWithCourses,
  ): Promise<void> => {
    try {
      const {
        data: { inserted: created },
      } = await axios.post<{ inserted: Event.Type }>(uri`/api/v1/event`, event);

      enqueueSnackbar('Event created', { variant: 'success' });
      navigate(uri`/event/${created._id}`, { replace: true });
    } catch (err: unknown) {
      captureException(err);
      if (err instanceof Error) {
        enqueueSnackbar(`Unable to create: ${err.message}`, {
          variant: 'error',
        });
      } else {
        throw err;
      }
    }
    return Promise.resolve();
  };

  return CreateEventView({ handleCreateEvent, state });
};

export default Controller;
