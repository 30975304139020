import { captureException } from '@sentry/browser';
import type { DropResult } from '@smooth-dnd/react';
import type { CourseControl, EventControl, ResolvedControl } from 'core';
import { fromEventControls, resolveLinearControls } from 'core';
import { useFormik, type FormikProps } from 'formik';
import { produce } from 'immer';
import type { ComponentType } from 'react';
import { useMemo } from 'react';
import type { NewCourse } from '../../../types.js';

interface Props {
  course: NewCourse;
  eventControls: Record<string, EventControl>;
  eventType: string | null;
  onCancelClick: () => void;
  onDoneClick: (course: NewCourse) => void;
  onUpdate: (course: NewCourse) => void;
}

interface ViewProps {
  formik: FormikProps<NewCourse>;
  eventType: string | null;
  onAddControl: (control: string) => void;
  onCancelClick: () => void;
  onDoneClick: () => void;
  onDrop: (action: DropResult) => void;
  resolvedControls: {
    start: ResolvedControl | undefined;
    finish: ResolvedControl | undefined;
    controls: ResolvedControl[];
  };
}

export const withViewState =
  <P extends Props>(Component: ComponentType<ViewProps>): React.FC<P> =>
  ({
    course,
    eventControls,
    eventType,
    onCancelClick,
    onDoneClick,
    onUpdate,
  }) => {
    const formik = useFormik<NewCourse>({
      initialValues: course,
      onSubmit: onDoneClick,
      onReset: onCancelClick,
    });

    const resolvedControls = useMemo(() => {
      const resolved = resolveLinearControls(
        formik.values.controls,
        fromEventControls(eventControls),
      );
      const hasStart = resolved.length && resolved[0].type === 'start';
      const hasFinish =
        resolved.length && resolved[resolved.length - 1].type === 'finish';
      return {
        start: hasStart ? resolved[0] : undefined,
        finish: hasFinish ? resolved[resolved.length - 1] : undefined,
        controls: resolved.slice(
          hasStart ? 1 : undefined,
          hasFinish ? -1 : undefined,
        ),
      };
    }, [formik.values.controls, eventControls]);

    const onDrop = (action: DropResult): void => {
      const update = produce<CourseControl[]>(
        resolvedControls.controls,
        (draft) => {
          const moved =
            action.removedIndex !== null
              ? draft[action.removedIndex]
              : undefined;
          if (action.removedIndex !== null) {
            draft.splice(action.removedIndex, 1);
          }
          if (action.addedIndex !== null) {
            if (moved) {
              draft.splice(action.addedIndex, 0, moved);
            } else {
              draft.splice(action.addedIndex, 0);
            }
          }

          if (resolvedControls.start) {
            draft.unshift(resolvedControls.start);
          }

          if (resolvedControls.finish) {
            draft.push(resolvedControls.finish);
          }
        },
      );

      formik.setFieldValue('controls', update).catch(captureException);
      onUpdate({ ...course, controls: update });
    };

    const onAddControl = (control: string): void => {
      const resolved = resolveLinearControls(
        [{ _id: control }],
        fromEventControls(eventControls),
      )[0];
      const update = produce<CourseControl[]>(
        resolvedControls.controls,
        (draft) => {
          if (!['start', 'finish'].includes(resolved.type ?? 'control')) {
            draft.push({ _id: control });
          }

          if (resolvedControls.start) {
            draft.unshift(resolvedControls.start);
          }
          if (resolved.type === 'start') {
            draft.unshift({ _id: control });
          }

          if (resolvedControls.finish) {
            draft.push(resolvedControls.finish);
          }
          if (resolved.type === 'finish') {
            draft.push({ _id: control });
          }
        },
      );

      formik.setFieldValue('controls', update).catch(captureException);
      onUpdate({ ...course, controls: update });
    };

    return (
      <Component
        formik={formik}
        eventType={eventType}
        onAddControl={onAddControl}
        onCancelClick={onCancelClick}
        onDoneClick={() => onDoneClick(formik.values)}
        onDrop={onDrop}
        resolvedControls={resolvedControls}
      />
    );
  };
