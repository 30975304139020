import { Add as AddIcon } from '@mui/icons-material';
import { IconButton, InputAdornment } from '@mui/material';
import { useFormik } from 'formik';
import type { FC } from 'react';
import { FormikTextField } from '../../../../../components/formik-fields/text-field.js';

interface Props {
  onAddControl: (control: string) => void;
}

export const AddControl: FC<Props> = ({ onAddControl }) => {
  const formik = useFormik<{ control: string }>({
    initialValues: { control: '' },
    onSubmit: (values) => {
      onAddControl(values.control);
      formik.resetForm({ values: { control: '' }, errors: {}, touched: {} });
    },
  });

  return (
    <form onReset={formik.handleReset} onSubmit={formik.handleSubmit}>
      <FormikTextField
        id="control"
        formik={formik}
        label="Add Control"
        endAdornment={
          <InputAdornment position="end">
            <IconButton aria-label="add control" edge="end" type="submit">
              <AddIcon />
            </IconButton>
          </InputAdornment>
        }
      />
    </form>
  );
};
